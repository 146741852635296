
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props:{
    width: {
      type: Number
      , default: 250
    }
    , useButtons: {
      type: Boolean
      , default: true
    }
  },
  emits: ['clear-filters', 'apply'],
  setup() {

    return {
    };
  },
});
