export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "LABELS": {
          "ADVANCE_SERACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busqueda Avanzada"])},
          "FOLIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folio de Salida"])},
          "FOLIO_OC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folio de Venta"])},
          "NAME_CUSTOMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientes"])},
          "DATE_DELIVERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de Salida"])},
          "FOLIO_CUSTOMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folio Cliente"])},
          "TYPE_OUTPUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo de Salida"])},
          "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estatus"])}
        },
        "BUTTONS": {
          "CLEAN_FILTERS_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpiar"])},
          "SEARCH_INPUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busqueda de Entradas"])}
        }
      }
    }
  })
}
