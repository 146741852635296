const getCriteria = (objFilter) => {
    let criteria = "Criteria.Active=true";
    if(existsFilterValues(objFilter)){
        criteria = "";
        criteria = addCriteriaFilter(criteria, "CreatedAt", objFilter.CreatedAt); //
        criteria = addCriteriaFilter(criteria, "EMovementType", objFilter.EMovementType);
        criteria = addCriteriaFilter(criteria, "Active", objFilter.Active);
        criteria = addCriteriaFilter(criteria, "RelatedMovementId", objFilter.RelatedMovementId);
        criteria = addCriteriaFilter(criteria, "LiquidationId", objFilter.LiquidationId);
        criteria = addCriteriaFilter(criteria, "ShipAdminId", objFilter.ShipAdminId);
        criteria = addCriteriaFilter(criteria, "Status", objFilter.Status);
    }

    return criteria;
}

const existsFilterValues = (objFilter) => {
    return (objFilter.CreatedAt != null 
    || objFilter.EMovementType != null 
    || objFilter.Active != null 
    || objFilter.RelatedMovementId != null
    || objFilter.LiquidationId != null
    || objFilter.ShipAdminId != null
    || objFilter.Status != null);
}

const addCriteriaFilter = (criteria, name, value) => {
    criteria += value != null ? `${criteria != '' ? '&' : ''}Criteria.${name}=${value}` : "";
    return criteria;
}

let filter = {};
filter.criteria = getCriteria;

export { filter }