<template>
    <FilterDynamic
        @clear-filters="clean"
        @apply="apply">
        <div class="mb-10">
            <div class="block">
                <label class="fs-6 fw-bold mb-2">Fecha de Creación</label>
                <el-date-picker
                    v-model="filters.CreatedAt"
                    type="date"
                    placeholder="Seleccione el día"
                    :size="size"
                />
            </div>
            <div v-if="requireOC" class="block" style="padding-top: 16px;">
                <label class="fs-6 fw-bold mb-2">Orden de Compra</label>
                <el-select
                    :disabled="false"
                    :multiple="false"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="Orden de Compra"
                    :remote-method="searchPurchaseOrders"
                    v-model="filters.RelatedMovementId"
                    @change="handleChangePurchaseOrderSelected"
                >
                    <el-option
                        v-for="option in getComboPurchaseOrders"
                        :key="option.id"
                        :label="option.name"
                        :value="option.id"
                    />
                </el-select>
            </div>
            <div v-if="requireLiquidation" class="block" style="padding-top: 16px;">
                <label class="fs-6 fw-bold mb-2">Liquidación</label>
                <el-select
                    :disabled="false"
                    :multiple="false"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="Liquidación"
                    :remote-method="searchLiquidations"
                    v-model="filters.LiquidationId"
                    @change="handleChangeLiquidationSelected"
                >
                    <el-option
                        v-for="option in getComboLiquidations"
                        :key="option.id"
                        :label="option.name"
                        :value="option.id"
                    />
                </el-select>
            </div>

            <div v-if="requireShipAdmin" class="block" style="padding-top: 16px;">
                <label class="fs-6 fw-bold mb-2">Administrador Barco</label>
                <el-select
                    :disabled="false"
                    :multiple="false"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="Administrador Barco"
                    :remote-method="searchShipAdmin"
                    v-model="filters.ShipAdminId"
                    @change="handleChangeShipAdminSelected"
                >
                    <el-option
                        v-for="option in getComboShipAdmins"
                        :key="option.id"
                        :label="option.name"
                        :value="option.id"
                    />
                </el-select>
            </div>

            <div class="block" style="padding-top: 16px;">
                <label class="fs-6 fw-bold mb-2">Total Registros:</label>
                <el-select
                    filterable
                    reserve-keyword
                    @change="handleChangeSelectedPageNumber"
                    v-model="defaultNumberId"
                    :disabled="false"
                >
                    <el-option
                        v-for="option in getComboPageNumbers"
                        :key="option.id"
                        :label="option.value"
                        :value="option.id"
                    />
                </el-select>
            </div>
        </div>
    </FilterDynamic>  
</template>

<script>
import { defineComponent, onMounted, ref, computed } from 'vue';
import FilterDynamic from "@/components/filters/FiltersDynamic.vue";
import ApiService from "@/core/services/ApiService";
import SwalMessageService from "@/core/services/SwalMessageService";
import { getMessageError } from '@/core/helpers/messageFromPulmeros';

export default defineComponent({
    components: {
        FilterDynamic
    },
    props: {
        fnCallbackFilters: {
            type: Function,
            required: true
        },
        requireOC: {
            type: Boolean,
            required: false,
            default: () => false
        },
        requireLiquidation: {
            type: Boolean,
            required: false,
            default: () => false
        },
        requireShipAdmin: {
            type: Boolean,
            required: false,
            default: () => false
        }
    },
    setup(props) {
        let defaultNumberId = ref(2);
        const pageNumbers = [
            { id: 1, value: 10 },
            { id: 2, value: 20 },
            { id: 3, value: 50 },
            { id: 4, value: 100 }
        ];
        const messageService = new SwalMessageService();
        let filters = ref(
            {
                CreatedAt: null,
                EMovementType: null,
                Active: null,
                RelatedMovementId: null,
                LiquidationId: null,
                ShipAdminId: null,
                Status: null,
                NumberItems: 20
            }
        );

        let lstPurchaseOrders = ref([]);
        let lstLiquidations = ref([]);
        let lstShipAdmins = ref([]);

        onMounted(() => {
            filters.value = getNullFilters();
        });

        const getComboPurchaseOrders = computed(() => { return lstPurchaseOrders.value; });
        const getComboLiquidations = computed(() => { return lstLiquidations.value; });
        const getComboShipAdmins = computed(() => { return lstShipAdmins.value; });
        const getComboPageNumbers = computed(() => { return pageNumbers; })

        const getNullFilters = () => {
            return {
                CreatedAt: null,
                EMovementType: null,
                Active: null,
                RelatedMovementId: null,
                LiquidationId: null,
                ShipAdminId: null,
                Status: null,
                NumberItems: 20
            }
        }

        const clean = () => {
            defaultNumberId.value = 2;
            filters.value = getNullFilters();
            lstPurchaseOrders.value = [];
            lstLiquidations.value = [];
            lstShipAdmins.value = [];
            apply();
        }
        const apply = () => {
            if(props.fnCallbackFilters) {
                filters.value.CreatedAt = filters.value.CreatedAt ? formatDate(filters.value.CreatedAt) : null;
                props.fnCallbackFilters(filters.value);
            }
        }

        const formatDate = (date) => {
            return `${date.getFullYear()}-${date.getMonth()}-${date.getDay()}`;
        }

        const searchPurchaseOrders = async(query) => {
            if(query.length >= 4) {
                await getComboOptions("PurchaseOrders", lstPurchaseOrders, "combo", "", query.trim().toUpperCase());
            }
        }

        const handleChangePurchaseOrderSelected = (id) => {
            if(id) {
                filters.value.RelatedMovementId = id;
            }
        }
        
        const searchLiquidations = async(query) => {
            if(query.length >= 4) {
                await getComboOptions("liquidation", lstLiquidations, "GetComboList", "", query);
            }
        }

        const handleChangeLiquidationSelected = (id) => {
            if(id) {
                filters.value.LiquidationId = id;
            }
        }
        const searchShipAdmin = async(query) => {
            if(query.length >= 4) {
                await getComboOptions("suppliers", lstShipAdmins, "combo", "", `${query}&Providers=false`);
            }
        }

        const handleChangeShipAdminSelected = (id) => {
            if(id) {
                filters.value.ShipAdminId = id;
            }
        }

        const getComboOptions = async(resource, container, method = "combo", parentId = "", criteria = "", fnCallback = null) => {
            let parentParam = parentId.trim() != "" ? `${parentId}/` : "";
            let paramCriteria = criteria.trim() != "" ? `?criteria=${criteria}` : "";
            let url = `${resource}/${parentParam}${method}${paramCriteria}`;
            ApiService.query(url).then(res => {
                container.value = res.data;
                if(fnCallback) {
                    fnCallback();
                }
            }).catch(reject => {
                getMessageError(reject, messageService);
            });
        }

        const handleChangeSelectedPageNumber = (id) => {
            let pageNumber = pageNumbers.find(p => p.id == id);
            if(pageNumber) {
                filters.value.NumberItems = pageNumber.value;
            }
        }

        return{
            defaultNumberId,
            clean,
            apply,
            filters,
            getComboPurchaseOrders,
            getComboLiquidations,
            searchPurchaseOrders,
            getComboShipAdmins,
            getComboPageNumbers,
            handleChangePurchaseOrderSelected,
            handleChangeLiquidationSelected,
            searchLiquidations,
            handleChangeShipAdminSelected,
            searchShipAdmin,
            handleChangeSelectedPageNumber
        }
    },
})
</script>
